import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

import missing from "./img/248342902.jpg"
import missing2 from "./img/248342902@2x.jpg"
import missing3 from "./img/248342902@3x.jpg"
import cyberMondayGif from "./img/263502582.gif"
import cyberMonday from "./img/cyber-monday.jpg"
import cyberMonday2 from "./img/cyber-monday@2x.jpg"
import cyberMonday3 from "./img/cyber-monday@3x.jpg"
import tacoDay from "./img/national-tacoday.jpg"
import tacoDay2 from "./img/national-tacoday@2x.jpg"
import tacoDay3 from "./img/national-tacoday@3x.jpg"
import tacoShirt from "./img/national-taco-day.jpg"
import tacoShirt2 from "./img/national-taco-day@2x.jpg"
import tacoShirt3 from "./img/national-taco-day@3x.jpg"
import triple from "./img/taco-melt.jpg"
import triple2 from "./img/taco-melt@2x.jpg"
import triple3 from "./img/taco-melt@3x.jpg"
import letsDoThis from "./img/63-welcome-account-registration-2-no-purchase-v-34-2-x.jpg"
import letsDoThis2 from "./img/63-welcome-account-registration-2-no-purchase-v-34-2-x@2x.jpg"
import letsDoThis3 from "./img/63-welcome-account-registration-2-no-purchase-v-34-2-x@3x.jpg"
import freeCombo from "./img/freeCombo.gif"
import comboBody from "./img/freeComboBody.png"
import tbApp from "./img/tb-2018-launch-migrateuser-appoffer-v-10-2-x.jpg"
import tbApp2 from "./img/tb-2018-launch-migrateuser-appoffer-v-10-2-x@2x.jpg"
import tbApp3 from "./img/tb-2018-launch-migrateuser-appoffer-v-10-2-x@3x.jpg"
import pickup from "./img/drive-thru.jpg"
import pickup2 from "./img/drive-thru@2x.jpg"
import pickup3 from "./img/drive-thru@3x.jpg"

import phone from "./img/phone.png"
import gif1 from "./img/phoneGif1.gif"
import rectangle from "./img/rectangle.png"
import tacowords from "./img/tacowords.png"
import people3 from "./img/and-we-got-the-tacos-to-their-people@3x.png"

import v2 from "./img/tacobell-v-2.jpg"
import tbphone3 from "./img/Thesis_casestudy_Tacobell__flat_desktop_1.gif"

export default class Creative extends Component {
  render() {
    return (
      <section className={styles.Creative}>
        <div className={styles.body}>
          <Reveal>
            <div className={styles.title}>
              <h3>Best-laid plans thrive with best-best</h3>
              <div className={styles.h2}>CREATIVE</div>
            </div>
          </Reveal>

          <Reveal>
            <div className={styles.subBody1}>
              <div className={styles.text}>
                <div className={styles.h4}>
                  That looks like a whole lot o&apos; taco campaigns.
                </div>
              </div>
            </div>
          </Reveal>

          <Reveal>
            <div className={styles.campaigns}>
              <img
                src={missing}
                srcSet={`${missing} 91w, ${missing2} 182w, ${missing3} 273w`}
                loading="lazy"
              />
              <img src={cyberMondayGif} />
              <img
                src={cyberMonday}
                srcSet={`${cyberMonday} 91w, ${cyberMonday2} 182w, ${cyberMonday3} 273w`}
                loading="lazy"
              />
              <img
                src={tacoDay}
                srcSet={`${tacoDay} 91w, ${tacoDay2} 182w, ${tacoDay3} 273w`}
                loading="lazy"
              />
              <img
                src={tacoShirt}
                srcSet={`${tacoShirt} 91w, ${tacoShirt2} 182w, ${tacoShirt3} 273w`}
                loading="lazy"
              />
              <img
                src={triple}
                srcSet={`${triple} 91w, ${triple2} 182w, ${triple3} 273w`}
                loading="lazy"
              />
              <img
                src={letsDoThis}
                srcSet={`${letsDoThis} 91w, ${letsDoThis2} 182w, ${letsDoThis3} 273w`}
                loading="lazy"
              />
              <img src={freeCombo} loading="lazy" />
              <img src={comboBody} loading="lazy" />
              <img
                src={tbApp}
                srcSet={`${tbApp} 91w, ${tbApp2} 182w, ${tbApp3} 273w`}
                loading="lazy"
              />
              <img
                src={pickup}
                srcSet={`${pickup} 91w, ${pickup2} 182w, ${pickup3} 273w`}
                loading="lazy"
              />
            </div>
          </Reveal>

          <Reveal>
            <div className={styles.gamified}>
              <h2>
                We gamified.
                <br /> We super-surprised <br />
                and double-delighted.
                <br /> We reminded the people <br />
                to get their tacos.
              </h2>
            </div>
          </Reveal>

          <div>
            <div className={styles.phoneSection}>
              <img className={styles.rectangle} src={rectangle} alt="" />
              <div className={styles.wrapping}>
                <div className={styles.phoneGif}>
                  <img className={styles.gif} src={gif1} alt="" />
                  <img className={styles.phone} src={phone} alt="" />
                </div>
                <div className={styles.tacoPeople}>
                  <img src={tacowords} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

// const Svg = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="272"
//     height="142"
//     viewBox="0 0 272 142"
//   >
//     <g
//       fill="#000"
//       fill-rule="evenodd"
//       font-family="Circular Std"
//       font-size="45"
//       font-style="italic"
//       font-weight="700"
//     >
//       <text opacity=".119" transform="translate(4 -8)">
//         <tspan x="6" y="45">
//           And we got
//         </tspan>
//         <tspan x="6" y="95">
//           the tacos to
//         </tspan>
//         <tspan x="6" y="145">
//           their people.{" "}
//         </tspan>
//       </text>
//       <text transform="translate(4 -8)">
//         <tspan x="0" y="45">
//           And we got
//         </tspan>
//         <tspan x="0" y="95">
//           the tacos to
//         </tspan>
//         <tspan x="0" y="145">
//           their people.{" "}
//         </tspan>
//       </text>
//     </g>
//   </svg>
// )
