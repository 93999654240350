import React, { Component } from "react"

import styles from "./styles.module.less"

// import el from "./img/logo-big-main-copy@3x.png"
// import logo2 from "./img/blue-logo@2x.png"
// import logo3 from "./img/blue-logo@3x.png"
// import slead2 from "./img/1-asset-1-d@2x.png"
// import slead3 from "./img/1-asset-1-d@3x.png"
// import leader2 from "./img/2018-el-wk-event-selects-15@2x.png"
// import leader3 from "./img/2018-el-wk-event-selects-15@3x.png"
// import seeing2 from "./img/dsc-0496@2x.png"
// import seeing3 from "./img/dsc-0496@3x.png"
import tbLogo from "./img/taco-bell-logo-png-transparent@3x.png"

export default class Support extends Component {
  render() {
    return (
      <section className={styles.Supports}>
        <div className={styles.relative}>
          <div className={styles.hero}>
            <div className={styles.textsm}>Case Study: Taco Bell</div>
            <div className={styles.textlg}>
              Great customer experience is rooted in genuine connection.
            </div>
          </div>
        </div>
        <div className={styles.intro}>
          <div className={styles.text}>
            <div className={styles.header}>
              <h2>
                Personalizing
                <br className={styles.dthide} /> eComm{" "}
                <br className={styles.dthide} /> for Taco Bell
              </h2>
            </div>
            <div className={styles.body}>
              <p>
                You’re great at tacos—customers love you. But how to make it
                easier for your customers to maximize that taco joy?
                <br />
                <br /> Make sure your website, mobile app, and email program are
                in sync. Then make sure you’ve got a clear view of your
                customer’s behavior across all three.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.logo}>
          <div className={styles.borderBlock}>
            <h3>How to get after it:</h3>
            <h2>Put user experience first.</h2>
            <h2>Build systems that make customer behavior visible.</h2>
            <h2>Adjust as you figure out what people need and want.</h2>
          </div>
        </div>

        <div className={styles.summary}>
          <div className={styles.header}>
            <h2>
              <em>Essentially: use tech to make a genuine connection.</em>
            </h2>
          </div>
          <div className={styles.text}>
            <div className={styles.tbLogo}>
              <img src={tbLogo} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
