import React, { Component } from "react"

import styles from "./styles.module.less"

import phone2 from "./img/phone@2x.png"
import phone3 from "./img/phone@3x.png"
import phoneimg from "./img/Thesis_casestudy_Tacobell_Desktop_flat_3.gif"
import greenRectangle from "./img/greenRectangle.png"
import phone from "./img/phone.png"
import gif2 from "./img/phoneGif2.gif"

export default class Phone extends Component {
  render() {
    return (
      <section className={styles.Phone}>
        <div className={styles.phoneSection}>
          <img className={styles.rectangle} src={greenRectangle} alt="" />
          <div className={styles.wrapping}>
            <div className={styles.phoneGif}>
              <img className={styles.gif} src={gif2} alt="" />
              <img className={styles.phone} src={phone} alt="" />
            </div>
            <div className={styles.tacoPeople}>
              Throw in a dynamic email and notification strategy, an optimized
              ecommerce website, and an app relaunch, and that’s one lean, mean,
              taco-vending machine.
            </div>
          </div>
        </div>
      </section>
    )
  }
}
