import React, { Component } from "react"

import styles from "./styles.module.less"

//import tbOrderReady2 from "./img/257692608@2x.png"
//import tbOrderReady3 from "./img/257692608@3x.png"
import orderGif from "./img/order_ready.gif"
// import logo2 from "./img/stories-whats-new-1@2x.jpg"
// import logo3 from "./img/stories-whats-new-1@3x.jpg"
import tacos from "./img/tacos@2x.jpg"
import emails from "./img/258609466.gif"
import gradCap from "./img/gradcap@2x.jpg"

export default class Strategy extends Component {
  render() {
    return (
      <section className={styles.Strategy}>
        <div className={styles.imageBg}>
          <div className={styles.title}>
            <h3>Strong results start with a strong</h3>
            <h2>STRATEGY</h2>
            <div className={styles.titleSub}>
              Start with an audit. What platforms are you working with, and are
              they doing the work you need them to?
              <div className={styles.efforts}>
                <h4>That includes a couple bigger efforts: </h4>
              </div>
            </div>
          </div>
          <div className={styles.images}>
            <img className={styles.imgOrder} src={orderGif} />
          </div>
          <br />
          <div className={styles.conclusion}>
            <p>
              <strong>Get SEO on point.</strong>
              <br />
              Make it consistent and make it thorough.
            </p>
            <p>
              <strong>Tag all customer interactions,</strong>
              <br />
              so you can easily find the behaviors you’re curious about, as well
              as track the success of your decisions.
            </p>

            <p>
              <strong>
                Message customers what they need,{" "}
                <br className={styles.dthide} />
                when they need it—
              </strong>
              <br /> intentional transactional and trigger notifications to help
              improve their experience and their retention.
            </p>
          </div>
        </div>
        <div className={styles.images2}>
          <div className={styles.tacoImgBox}>
            <img src={tacos} className={styles.tacos} />
          </div>
          <div className={styles.twoImg}>
            <img src={gradCap} className={`${styles.gradCap}`} />
            <img src={emails} className={styles.emails} />
          </div>
        </div>
      </section>
    )
  }
}
