import React, { Component } from "react"

import Reveal from "react-reveal/Reveal"

import Support from "./components/Support"
import Strategy from "./components/Strategy"
import Creative from "./components/Creative"
import Tech from "./components/Tech"
import Phone from "./components/Phone"
import Out from "./components/Out"
import Bottom from "./components/Bottom"

export default class TBCase extends Component {
  render() {
    return (
      <div>
        <Reveal>
          <Support />
        </Reveal>
        <Reveal>
          {" "}
          <Strategy />
        </Reveal>{" "}
        <Creative />{" "}
        <Reveal>
          <Tech />
        </Reveal>
        <Reveal>
          <Phone />
        </Reveal>
        <Reveal>
          {" "}
          <Out />
        </Reveal>
        <Bottom />
      </div>
    )
  }
}
