import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import TBCase from "../../components/CaseStudies/TBCase"

const TacoBellCaseStudy = () => (
  <Layout backgroundColor={"white"} bannerBgColor="" isArchive>
    <SEO
      title="Thesis | Case study: Taco Bell"
      description="We helped Taco Bell personalize their ecomm, putting user experience first and building systems that track and measure user behavior. Read the case study."
    />
    <TBCase />
  </Layout>
)

export default TacoBellCaseStudy
