import React, { Component } from "react"

import styles from "./styles.module.less"

import thankYouGif from "./img/TACO-108Thank you_1B.gif"

export default class Out extends Component {
  render() {
    return (
      <section className={styles.Out}>
        <div className={styles.borderBlock}>
          <h3>How it worked out:</h3>
          <h2>
            Taco Bell’s website and app now prioritize customer experience. And
            the synced-up notification strategy pushes customers to revisit the
            app to purchase … more often.
            <br />
            <br /> The information loop is complete: Taco Bell gets to the
            people, and the people get to the tacos.
          </h2>
        </div>
        <div className={styles.endGif}>
          <img src={thankYouGif} />
        </div>
        <div className={styles.conclusion}>
          <div className={styles.h6}>Credits</div>
          <div className={styles.text3}>
            Thanks to the Taco Bell Team. AKA the real people who made the real
            work.
            <br />
            <b>Account Manager:</b> Daryl Faulkner, Caitlin Leonard <br />
            <b>Strategist:</b> Stephen Hammill, Cher Fuller
            <br />
            <b>SEO/Tagging:</b> Malorie Morrison
            <br />
            <b>Operations:</b> Aaron Petrous
            <br />
            <b>Designers:</b> Sean Sanford, Tammi Heneveld, Eli Caudillo, Tyler
            Varnau, Allison Bowe, Tatiana Mac
            <br />
            <b>Writers:</b> Spencer Foxworth, Annie Russell, Elizabeth Harney
          </div>
        </div>
      </section>
    )
  }
}
